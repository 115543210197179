import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { BtnControlHeroAudio } from './Button'
import { isMobile } from '@/helper/checkDeviceMedia'

const HeroVideo = ({
   url,
   isMuted = true,
   _handlePlayAudio = () => {},
   extraClass = '',
   forwardedRef = {},
}) => {
   const [hasWindow, setHasWindow] = useState(false)
   const isMobileMatchMedia = isMobile()

   useEffect(() => {
      if (typeof window !== 'undefined') {
         setHasWindow(true)
      }
   }, [])

   return (
      <>
         {hasWindow && (
            <section className={'hero-video ' + extraClass} ref={forwardedRef}>
               <div className="player-wrapper">
                  <ReactPlayer
                     url={url}
                     playing
                     loop
                     volume={0.2}
                     muted={isMuted}
                     height="100%"
                     width="100%"
                     className="react-player"
                     loading="lazy"
                     playsinline={true}
                     fileConfig={{ attributes: { autoPlay: true } }}
                     // config={{
                     //    file: {
                     //       attributes: {
                     //          poster: './images/gx-opening.jpg',
                     //       },
                     //    },
                     // }}
                  />

                  {/* <div className="overlay-video"></div> */}

                  {!isMobileMatchMedia ? (
                     <BtnControlHeroAudio
                        isMuted={isMuted}
                        _handlePlayAudio={_handlePlayAudio}
                     />
                  ) : null}
               </div>
            </section>
         )}
      </>
   )
}

const ForwardedComponent = React.forwardRef((props, ref) => (
   <HeroVideo {...props} forwardedRef={ref} />
))

export default ForwardedComponent
